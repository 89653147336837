import React, { useState, useEffect } from 'react'
import styles from "./LeadForm.module.css"
import ContactForm from '../../components/ContactForm/ContactForm'
import ReusableModal from '../../components/ReusableModal/ReusableModal';
import marcusevans from "../../Assets/marcusEvansLogo.svg";
import marcusEvansBlack from "../../Assets/marcusevans.svg"

const LeadForm = () => {
    const [modalOpenForUser, setmodalOpenForUser] = useState(false);
    const [formData, setFormData] = useState({
        UserType: ""
    });
    const handleuserType = () => {
        if (formData.UserType) {
            localStorage.setItem('userType', formData.UserType);
            setmodalOpenForUser(false);
        }
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        let newValue = type === 'checkbox' ? checked : value;

        setFormData({
            ...formData,
            [name]: newValue,
        });
    };

    const handleOpenModel = () => {
        setmodalOpenForUser(true)
    }


    useEffect(() => {
        const userType = localStorage.getItem('userType');
        if (!userType) {
            setmodalOpenForUser(true);
        } else {
            setFormData(prevState => ({ ...prevState, UserType: userType }));
        }
    }, []);

    return (
        <div>
            <div className={styles.header}>
                <div className={styles.headerImageCon}>
                    <img src={marcusevans} alt="marcus evans" className={styles.headerImage} />
                </div>

                <div className={styles.editBtn}>
                    <button className={styles.btn} onClick={handleOpenModel} >Edit User Type</button>
                </div>

            </div>
            <div className={styles.ContactContainer}>
                <ContactForm />
            </div>

            <ReusableModal
                isOpen={modalOpenForUser}
                handleClose={() => setmodalOpenForUser(false)}
                maxWidth={60}
                disableBackdropClick={true}
            >
                <div className={styles.modalOverlay}>
                    <div className={styles.WrapperimgCon}>
                        <div className={styles.imgContainer}>
                            <img src={marcusEvansBlack} alt="marcus evans" className={styles.meImage} />
                        </div>
                    </div>

                    <div className={styles.modalContent}>
                        <p className={styles.contactContainertext}>Please specify which area of the business you are most involved in:</p>
                        <select name="UserType" value={formData?.UserType} onChange={handleChange} className={styles.dropdown}>
                            <option value="" disabled>Please select an option</option>
                            <option value="DA">DA, for example "DA Sales, DRM"</option>
                            <option value="SP">SP, for example "SP Sales"</option>
                            <option value="PROD">PROD, for example "Producer"</option>
                            <option value="MKT">MKT, for example "Marketing Executive, Digital Marketing"</option>
                            <option value="OTHER">OTHER, for example "Finance, IT"</option>
                        </select>
                        <div className={styles.wrapperButton}>
                            <button onClick={handleuserType} className={styles.closeButton}>Submit</button>
                        </div>
                    </div>
                </div>

            </ReusableModal >
        </div>
    )
}

export default LeadForm
