import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { useMediaQuery, useTheme } from '@mui/material';

const ReusableModal = ({ isOpen, handleClose, children, maxWidth = 50, disableBackdropClick = false }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));


    const handleDialogClose = (event, reason) => {
        if (!disableBackdropClick || reason !== 'backdropClick') {
            handleClose();
        }
    };


    return (
        <Dialog
            open={isOpen}
            onClose={handleDialogClose}
            aria-labelledby="dialog-title"
            aria-describedby="dialog-description"
            PaperProps={{
                sx: {
                    width: isMobile ? '100%' : `${maxWidth}%`, // Set width in percentage
                },
            }}
        >
            <DialogContent>
                {children}
            </DialogContent>
        </Dialog>
    );
};

export default ReusableModal;
