import React from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
  Navigate,
} from "react-router-dom";
import HubSpotLogin from "./pages/HubSpotLogin/HubSpotLogin";
import Form from "./pages/Form/Form";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute"
import LeadForm from "./pages/LeadForm/LeadForm";

const RedirectIfAuthenticated = ({ children }) => {
  const accessToken = localStorage.getItem('access_token');
  if (accessToken) {
    return <Navigate to="/form" />;
  }
  return children;
};

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <RedirectIfAuthenticated>
        <HubSpotLogin />
      </RedirectIfAuthenticated>
    ),
  },
  {
    path: "/auth/callback",
    element: <Form />,
  },

  {
    path: "/form",
    element: (
      <ProtectedRoute element={<LeadForm />} />
    ),
  },
]);


const App = () => {
  return (
      <RouterProvider router={router} />
  );
};

export default App;
