import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from "./Form.module.css";
import api from '../../Utils/Network';

const Form = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoadingdata] = useState(true);

  useEffect(() => {
    const fetchTokens = async () => {
      const storedAccessToken = localStorage.getItem('access_token');

      if (!storedAccessToken) {
        const params = new URLSearchParams(location.search);
        const code = params.get('code');

        if (code) {
          const controller = new AbortController();
          const { signal } = controller;

          try {
            const response = await api.get(`/auth/callback?code=${code}`, { signal });
            const { access_token, refresh_token } = response.data;
            localStorage.setItem('access_token', access_token);
            localStorage.setItem('refresh_token', refresh_token);
            console.log("enter in frontend",refresh_token);
            
            navigate('/form');
          } catch (error) {
            if (error.name === 'CanceledError') {
              console.log('Request was canceled');
            } else if (error.response) {
              console.error('Error response data:', error.response.data);
              console.error('Error response status:', error.response.status);
              console.error('Error response headers:', error.response.headers);
            } else if (error.request) {
              console.error('Error request:', error.request);
            } else {
              console.error('Error message:', error.message);
            }
          } finally {
            setIsLoadingdata(false);
          }

          return () => controller.abort(); // Move cleanup here
        } else {
          setIsLoadingdata(false); // If no code is present, update loading state
        }
      } else {
        setIsLoadingdata(false); // Token already exists, no need to load
      }
    };

    fetchTokens();
  }, [location.search, navigate]);

  if (isLoading) {
    return (
      <>
      <div className={styles.formWarpper}>
      <p className={styles.loadingtext}>Loading...</p>
      </div>

      </>
    );
  }

  return null; // Return null or any other content when loading is complete
};

export default Form;
