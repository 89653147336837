import React, { useState, useEffect } from 'react';
import styles from './ContactForm.module.css';
import ReusableModal from '../ReusableModal/ReusableModal';
import api from '../../Utils/Network';
import SecondForm from './SecondForm';
import ContactFound from './ContactFound';
import ContactListFull from './ContactListFull';
import ReusableSnackbar from '../ReusableSnackbar/ReusableSnackbar';

const ContactForm = () => {
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success',
    });
    const [searchContactDetails, setSearchContactDetails] = useState([])
    const [loadingSearch, setLoadingSearch] = useState(false);
    const [hubspotId, setHubSpotID] = useState(null);
    const [loadingEvent, setLoadingEvent] = useState(false);
    const [listingData, setListingData] = useState({
        lifecycleStageListing: [],
        leadTypeListing: [],
        setSuggestions: [],
        countryListing: [],
        setUserListing: [],
        setIsSelectingItem: false,
    })
    const [formData, setFormData] = useState({
        email: '',
        firstName: '',
        lastName: '',
        jobTitle: '',
        companyName: '',
        countryRegion: null,
        phoneNumber: '',
        lifecycleStage: '',
        leadType: '',
        company: '',
        searchEvent: '',
        marketingContact: false,
        LinkedEventHubSpotId: null,
        UserType: ""
    });
    const [isFormValid, setIsFormValid] = useState(false);
    const [errors, setErrors] = useState({});
    const [showAllFields, setShowAllFields] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    // const [contactF, setContactf] = useState(false);
    const [singleUser, setSingleUser] = useState({})

    const handleSecondFormClear = () => {
        setFormData((prev) => ({
            ...prev,
            email: '',
            firstName: '',
            lastName: '',
            jobTitle: '',
            companyName: '',
            countryRegion: null,
            phoneNumber: '',
            // lifecycleStage: '',
            // leadType: '',
            company: '',
            searchEvent: '',
            marketingContact: false,
            LinkedEventHubSpotId: null
        }));
        setErrors({});
        setShowAllFields(false);
    }


    const handleClearandSnackbar = () => {
        setFormData((prev) => ({
            ...prev,
            email: '',
            firstName: '',
            lastName: '',
            jobTitle: '',
            companyName: '',
            countryRegion: null,
            phoneNumber: '',
            // lifecycleStage: '',
            // leadType: '',
            company: '',
            searchEvent: '',
            marketingContact: false,
            LinkedEventHubSpotId: null
        }));
        setErrors({});
        setShowAllFields(false)

        setSnackbar({
            open: true,
            message: 'Contact created successfully!',
            severity: 'success',
        });
    }

    const handleCloseModal = () => {
        setModalOpen(false);
    };
    const validateField = (name, value) => {
        let error = '';
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const textRegex = /^[a-zA-Z\s]+$/;
        const phoneRegex = /^\+?[0-9\s\-]+$/;
        const nameRegex = /^[a-zA-Z0-9\s\-'\.áéíóúñüÁÉÍÓÚÑÜ]+$/;



        switch (name) {
            case 'email':
                if (value && !emailRegex.test(value)) error = 'Invalid email format';
                break;
            case 'phoneNumber':
                if (value && !phoneRegex.test(value)) error = 'Invalid phone number';
                break;
            default:
                break;
        }

        setErrors({ ...errors, [name]: error });
    };
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        let newValue = type === 'checkbox' ? checked : value;

        if (name === 'phoneNumber') {
            newValue = newValue.replace(/\D/g, '');
            if (newValue.length > 15) {
                newValue = newValue.slice(0, 15);
            }
        }
        setFormData({
            ...formData,
            [name]: newValue,
        });
        validateField(name, newValue);
    };
    const handleLinkOwner = async (val = false) => {

        const userType = localStorage.getItem('userType') || '';

        const data = {
            HubSpotContactId: hubspotId,
            UserType: userType
        };

        try {
            const response = await api.post(`/link_contact`, data);
            if (val && response?.data?.ResponseData) {
                const hubSpotContactURL = response?.data.ResponseData;
                window.open(hubSpotContactURL, '_blank');
            }

            setModalOpen(false);
            handleSecondFormClear();
            setSnackbar({
                open: true,
                message: 'Contact linked successfully!',
                severity: 'success',
            });

        } catch (error) {
            let errormsg = error?.response?.data?.detail;
            setSnackbar({
                open: true,
                message: errormsg,
                severity: 'error',
            });
            setModalOpen(false);
        }
    };
    const handleCloseSnackbar = () => {
        setSnackbar(prev => ({
            ...prev,
            open: false,
        }));
    };

    const handleSelectfromList = (singledetails) => {
        setModalOpen(true);
        setHubSpotID(singledetails?.ContactHubSpotId)
        setSingleUser(singledetails)
    }

    const handleSearch = async (e) => {
        e.preventDefault();
        if (!errors?.email) {
            setLoadingSearch(true);
            const requestBody = {
                EmailAddress: formData?.email,
                FirstName: formData?.firstName.trim(),
                Surname: formData?.lastName.trim(),
                CompanyName: formData?.companyName.trim()
            };
            const userType = localStorage.getItem('userType') || '';

            try {
                const res = await api.post('/search', requestBody);
                if (res.data?.ResponseData?.length > 0) {
                    // if the contact is found then it will be in this if
                    setSearchContactDetails(res.data?.ResponseData);
                    handleSelectfromList(res.data?.ResponseData[0]);
                    return;

                    // if (res.data?.ResponseData?.length > 1) {

                    // }

                    // let hubspotId = res.data?.ResponseData[0]?.ContactHubSpotId;
                    // setSearchContactDetails(res.data?.ResponseData);
                    // try {
                    //     const response = await api.get(`/contact_owners_full?hubspotContactId=${hubspotId}&userType=${userType}`);
                    //     if (response.data?.ResponseData?.isFull) {
                    //         setContactf(true);
                    //         setModalOpen(true);
                    //     } else {
                    //         setContactf(false);
                    //         setModalOpen(true);
                    //         setHubSpotID(hubspotId)
                    //     }
                    // } catch (error) {
                    //     console.error('Error making the second request:', error);
                    // }
                }

                if (res.data?.ResponseData?.length == 0) {
                    setShowAllFields(true);
                }

            } catch (error) {
                console.error('Error making the request:', error);
            } finally {
                setLoadingSearch(false)
            }
        }
        return;

    }
    const fetchLifecycleStages = async () => {
        try {
            const response = await api.get('/lifecycle_stages');
            const stages = response.data?.ResponseData;
            setListingData(prev => ({
                ...prev,
                lifecycleStageListing: stages
            }));


        } catch (error) {
            console.error('Error fetching lifecycle stages:', error);
        }
    };

    useEffect(() => {
        if (listingData?.lifecycleStageListing.length > 0 && listingData?.leadTypeListing.length > 0) {
            setFormData(prev => ({
                ...prev,
                lifecycleStage: listingData?.lifecycleStageListing[1].key,
                leadType: listingData?.leadTypeListing[0].key
            }));
        }


    }, [listingData?.lifecycleStageListing, listingData?.leadTypeListing])

    const fetchLeadType = async () => {
        try {
            const response = await api.get('/lead_types');
            const leadTypes = response.data?.ResponseData;
            setListingData(prev => ({
                ...prev,
                leadTypeListing: leadTypes,
            }));


        } catch (error) {
            console.error('Error fetching lead types:', error);
        }
    };


    const getItems = async () => {
        try {
            setLoadingEvent(true);
            const response = await api.get(`/event_search?criteria=${encodeURIComponent(formData?.searchEvent)}`);
            const data = response?.data?.ResponseData;

            setListingData(prev => ({
                ...prev,
                setSuggestions: data
            }));
        } catch (error) {
            console.error('Error fetching suggestions:', error);
        } finally {
            setLoadingEvent(false);
        }
    };

    const fetchCountries = async () => {
        try {
            const response = await api.get('/countries');
            const responseData = response?.data.ResponseData;

            if (Array.isArray(responseData)) {
                const mergedCountries = responseData.flat();
                const countryOptions = mergedCountries.map(country => ({
                    value: country.Id,
                    label: country.CountryName
                }));

                setListingData(prevData => ({
                    ...prevData,
                    countryListing: countryOptions,
                }));
            }
        } catch (error) {
            console.error('Error fetching countries:', error);
        }
    };

    useEffect(() => {
        fetchCountries();
        fetchLifecycleStages();
        fetchLeadType();
    }, [])

    useEffect(() => {
        let debounceTimeout;

        if (formData?.searchEvent?.length > 2 && !listingData?.setIsSelectingItem) {
            clearTimeout(debounceTimeout);

            debounceTimeout = setTimeout(() => {
                getItems();
            }, 500);
        }

        if (formData?.searchEvent?.length === 3) {
            setListingData(prev => ({
                ...prev,
                setIsSelectingItem: false
            }));
        }

        return () => {
            clearTimeout(debounceTimeout);
        };
    }, [formData?.searchEvent, listingData?.setIsSelectingItem]);

    useEffect(() => {
        const isValid =
            formData.firstName &&
            formData.lastName &&
            formData.companyName &&
            !errors?.email;

        setIsFormValid(isValid);
    }, [formData, errors]);


    return (
        <>
            {!showAllFields && (<>
                <form onSubmit={handleSearch} className={styles.formContainer}>
                    <div className={styles.formHeader}>Create/Search contact</div>
                    <div className={styles.formBody}>


                        <div className={styles.formGroup}>
                            <label>
                                Email
                            </label>
                            <input
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                className={styles.inputField}
                            />
                            {errors.email && <p className={styles.errorText}>{errors.email}</p>}
                        </div>
                        <div className={styles.formGroup}>
                            <label>
                                First name <span className={styles.requiredAsterisk}>*</span>
                            </label>
                            <input
                                type="text"
                                name="firstName"
                                value={formData.firstName}
                                onChange={handleChange}
                                required
                                className={styles.inputField}
                            />
                            {errors.firstName && <p className={styles.errorText}>{errors.firstName}</p>}
                        </div>
                        <div className={styles.formGroup}>
                            <label>
                                Last name <span className={styles.requiredAsterisk}>*</span>
                            </label>

                            <input
                                type="text"
                                name="lastName"
                                value={formData.lastName}
                                onChange={handleChange}
                                required
                                className={styles.inputField}
                            />
                            {errors.lastName && <p className={styles.errorText}>{errors.lastName}</p>}
                        </div>

                        <div className={styles.formGroup}>
                            <label>
                                Company name <span className={styles.requiredAsterisk}>*</span>
                            </label>
                            <input
                                type="text"
                                name="companyName"
                                value={formData.companyName}
                                onChange={handleChange}
                                required
                                className={styles.inputField}
                            />
                            {errors.companyName && <p className={styles.errorText}>{errors.companyName}</p>}
                        </div>
                        <div className={styles.formFooter}>
                            <button type="submit"
                                className={`${isFormValid ? styles.buttonEnabled : styles.submitButton}`}
                                disabled={loadingSearch}
                            >   {loadingSearch ? 'Loading...' : 'Create'}</button>
                            <button type="button" className={styles.cancelButton} onClick={handleSecondFormClear}>Cancel</button>
                        </div>
                    </div>
                </form>

            </>)}

            {
                showAllFields && (
                    <>
                        <SecondForm
                            formData={formData}
                            handleChange={handleChange}
                            errors={errors}
                            setErrors={setErrors}
                            handleSecondFormClear={handleClearandSnackbar}
                            cancelBtn={handleSecondFormClear}
                            listingData={listingData}
                            setFormData={setFormData}
                            setListingData={setListingData}
                            loadingEvent={loadingEvent}
                            setShowAllFields={setShowAllFields}
                        />
                    </>)
            }
            <ReusableModal
                isOpen={modalOpen}
                handleClose={handleCloseModal}
                maxWidth={60}
            >
                {
                    <ContactFound
                        searchContactDetails={searchContactDetails}
                        handleCloseModal={handleCloseModal}
                        handleLinkOwner={handleLinkOwner}
                        formData={formData}
                        hubspotId={hubspotId}
                        handleSecondFormClear={handleSecondFormClear}
                        singleUser={singleUser}
                        handleSelectfromList={handleSelectfromList}
                    />
                }
            </ReusableModal >
            <ReusableSnackbar
                open={snackbar.open}
                handleClose={handleCloseSnackbar}
                message={snackbar.message}
                severity={snackbar.severity}
            />
        </>
    );
};

export default ContactForm;
