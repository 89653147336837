import React, { useState, useRef,useEffect } from 'react';
import styles from "./ContactForm.module.css";
import Select from 'react-select'
import api from '../../Utils/Network';
import ReusableSnackbar from '../ReusableSnackbar/ReusableSnackbar';



const SecondForm = ({ formData, handleChange, errors, setErrors, handleSecondFormClear,cancelBtn, listingData, setFormData, setListingData, loadingEvent,setShowAllFields }) => {
    const selectRef = useRef(null);
    const [loadingSecondForm, setLoadingSecondForm] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success',
    });
    const customStyles = {
        placeholder: (provided) => ({
            ...provided,
            color: '#888888',
            fontSize: '13px'
        }),
    };

    const handleCloseSnackbar = () => {
        setSnackbar(prev => ({
            ...prev,
            open: false,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let hasErrors = false;
        const newErrors = {};
        if (!formData.countryRegion) {
            newErrors.countryRegion = 'Country/Region is required';
            hasErrors = true;
            selectRef.current.focus();
        }
        if (hasErrors) {
            setErrors(newErrors);
            return;
        }

        if (!formData.LinkedEventHubSpotId) {
            alert('Please select an event from the dropdown menu after searching.');
            return;
        }
        const userType = localStorage.getItem('userType') || '';
        const submissionData = {
            Email: formData.email || "",
            PhoneNumber: formData.phoneNumber || "",
            LinkedEventHubSpotId: formData.LinkedEventHubSpotId !== null ? formData.LinkedEventHubSpotId : 0,
            FirstName: formData.firstName.trim(),
            Surname: formData.lastName.trim(),
            CompanyName:formData.companyName.trim(),
            JobTitle: formData.jobTitle.trim(),
            LifeCycleStage: formData.lifecycleStage,
            LeadType: formData.leadType,
            IsSetAsMarketingContact: formData.marketingContact,
            CountryId: formData.countryRegion?.value,
            CountryName: formData.countryRegion?.label,
            UserType: userType 
        }
        setLoadingSecondForm(true);
        try {
            const response = await api.post('/new_contact', submissionData);
            const hubSpotContactURL = response.data.ResponseData.HubSpotContactURL;
            window.open(hubSpotContactURL, '_blank');
            handleSecondFormClear();
        } catch (error) {
            const errorMessage = error?.response?.data?.detail || 'Error creating contact. Please try again.';
            setSnackbar({
                open: true,
                message: errorMessage,
                severity: 'error',
            });
        } finally {
            setLoadingSecondForm(false);
        }
    }
    const handleCountryRegionChange = (selectedOption) => {
        setFormData(prevData => ({
            ...prevData,
            countryRegion: selectedOption
        }));
        if (selectedOption) {
            setErrors(prevErrors => ({
                ...prevErrors,
                countryRegion: null
            }));
        }
    };

const handlePrev = (e) =>{
e.preventDefault();
setShowAllFields(false)

}

useEffect(() => {
    const isValid = formData.jobTitle && formData.countryRegion && formData.LinkedEventHubSpotId;
    setIsFormValid(isValid);
  }, [formData]);

    return (
        <div>
            <form onSubmit={handleSubmit} className={styles.formContainer}>
                <div className={styles.formHeader}>Create/Search contact</div>
                <div className={styles.formBody}>
                    <div className={styles.formGroup}>
                        <label>
                            Job title <span className={styles.requiredAsterisk}>*</span>
                        </label>
                        <input
                            type="text"
                            name="jobTitle"
                            value={formData.jobTitle}
                            onChange={handleChange}
                            required
                            className={styles.inputField}
                        />
                        {errors.jobTitle && <p className={styles.errorText}>{errors.jobTitle}</p>}
                    </div>

                    <div className={styles.formGroup}>
                        <label>
                            Country/Region <span className={styles.requiredAsterisk}>*</span>
                        </label>
                        <Select
                            ref={selectRef}
                            name="countryRegion"
                            value={formData.countryRegion}
                            onChange={handleCountryRegionChange}
                            options={listingData?.countryListing}
                            placeholder="Select a country..."
                            styles={customStyles}
                            // className={styles.selectField}
                            isSearchable
                        />
                        {errors.countryRegion && <p className={styles.errorText}>{errors.countryRegion}</p>}
                    </div>

                    <div className={styles.formGroup}>
                        <label>
                            Phone number
                        </label>
                        <input
                            type="tel"
                            name="phoneNumber"
                            value={formData.phoneNumber}
                            onChange={handleChange}
                            className={styles.inputField}
                            placeholder="Enter phone number"
                        />
                        {errors.phoneNumber && <p className={styles.errorText}>{errors.phoneNumber}</p>}
                    </div>
                    <div className={styles.formGroup}>
                        <label>
                            Lifecycle stage <span className={styles.requiredAsterisk}>*</span>
                        </label>
                        <select
                            name="lifecycleStage"
                            value={formData?.lifecycleStage}
                            onChange={handleChange}
                            required
                            className={styles.selectField}
                        >
                            {listingData?.lifecycleStageListing?.map(stage => (
                                <option key={stage?.key} value={stage?.key}>
                                    {stage.value}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className={styles.formGroup}>
                        <label>Lead type <span className={styles.requiredAsterisk}>*</span>
                        </label>
                        <select
                            name="leadType"
                            value={formData.leadType}
                            onChange={handleChange}
                            className={styles.selectField}
                            required
                        >
                            {listingData?.leadTypeListing?.map(stage => (
                                <option key={stage.key} value={stage.key}>
                                    {stage.key}
                                </option>
                            ))}
                        </select>
                        {errors.leadType && <p className={styles.errorText}>{errors.leadType}</p>}
                    </div>
                    <div className={styles.formGroup}>

                    </div>

                    <hr className={`${styles.hrLine} ${styles.formGroup}`} />
                    <p className={`${styles.formGroup}`}>Associate contact with</p>


                    <div className={`${styles.formGroup} ${styles.searchDropDown}`}>

                        <label>
                            Event<span className={styles.requiredAsterisk}>*</span>
                        </label>
                        <div className={styles.inputContainer}>
                            <input
                                type="text"
                                name="searchEvent"
                                value={formData?.searchEvent}
                                onChange={handleChange}
                                className={styles.inputField}
                                placeholder="Search for event code or name"
                                required
                            />
                            {formData?.searchEvent && (
                                <button
                                    type="button"
                                    className={styles.clearButton}
                                    onClick={() => {
                                        setFormData(prev => ({
                                            ...prev,
                                            searchEvent: '',
                                            LinkedEventHubSpotId: ''
                                        }));
                                        setListingData(prev => ({
                                            ...prev,
                                            setSuggestions: [],
                                            setIsSelectingItem: false
                                        }));
                                    }}
                                >
                                    ✕
                                </button>
                            )}
                        </div>
                        <div className={styles.suggestionsList}>
                            {loadingEvent ? (
                                <div className={styles.loading}>Loading...</div>
                            ) : (
                                listingData?.setSuggestions.map((suggestion) => (
                                    <div
                                        key={suggestion.Id}
                                        className={styles.suggestionItem}
                                        onClick={() => {
                                            setFormData(prev => ({
                                                ...prev,
                                                searchEvent: suggestion.EventName,
                                                LinkedEventHubSpotId: suggestion?.HubSpotId
                                            }));
                                            setListingData(prev => ({
                                                ...prev,
                                                setSuggestions: [],
                                                setIsSelectingItem: true
                                            }));
                                        }}
                                    >
                                        {suggestion.EventName}
                                    </div>
                                ))
                            )}
                        </div>
                    </div>


                    {/* <div className={styles.formGroup}>
                        <label className={styles.checkboxLabel}>
                            <div className={styles.checkboxContainer}>
                                <input
                                    type="checkbox"
                                    name="marketingContact"
                                    checked={formData.marketingContact}
                                    onChange={handleChange}
                                    className={styles.checkboxInput}

                                />
                                <p className={styles.textCon}>
                                    Set this contact as a marketing contact
                                    <span className={styles.svgContainer}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                            <path d="M9.3 13.5H10.7V9.3H9.3V13.5ZM10 7.9C10.1983 7.9 10.3647 7.8328 10.4991 7.6984C10.6335 7.564 10.7005 7.39786 10.7 7.2C10.6995 7.00213 10.6323 6.836 10.4984 6.7016C10.3645 6.5672 10.1983 6.5 10 6.5C9.80166 6.5 9.63553 6.5672 9.5016 6.7016C9.36766 6.836 9.30046 7.00213 9.3 7.2C9.29953 7.39786 9.36673 7.56423 9.5016 7.6991C9.63646 7.83396 9.8026 7.90093 10 7.9ZM10 17C9.03166 17 8.12166 16.8161 7.27 16.4484C6.41833 16.0807 5.6775 15.582 5.0475 14.9525C4.4175 14.323 3.91887 13.5821 3.5516 12.73C3.18433 11.8779 3.00047 10.9679 3 10C2.99953 9.03213 3.1834 8.12213 3.5516 7.27C3.9198 6.41786 4.41843 5.67703 5.0475 5.0475C5.67657 4.41797 6.4174 3.91933 7.27 3.5516C8.1226 3.18387 9.0326 3 10 3C10.9674 3 11.8774 3.18387 12.73 3.5516C13.5826 3.91933 14.3234 4.41797 14.9525 5.0475C15.5816 5.67703 16.0804 6.41786 16.4491 7.27C16.8178 8.12213 17.0014 9.03213 17 10C16.9986 10.9679 16.8147 11.8779 16.4484 12.73C16.0821 13.5821 15.5834 14.323 14.9525 14.9525C14.3216 15.582 13.5807 16.0809 12.73 16.4491C11.8793 16.8173 10.9693 17.0009 10 17Z" fill="#7B98B6" />
                                        </svg>
                                    </span>
                                </p>
                            </div>
                        </label>
                    </div> */}

                    <div className={styles.formFooter}>
                        <button type="submit"
                             className={`${isFormValid ? styles.buttonEnabled : styles.submitButton}`}
                            disabled={loadingSecondForm}
                        >  {loadingSecondForm ? 'Loading...' : 'Create and add another'}</button>
                        <button type="button" className={styles.buttonEnabled} onClick={handlePrev} >
                            Go to the previous form
                        </button>
                        <button type="button" className={styles.cancelButton} onClick={cancelBtn}>Cancel</button>
                    </div>

                </div>
            </form>

            <ReusableSnackbar
                open={snackbar.open}
                handleClose={handleCloseSnackbar}
                message={snackbar.message}
                severity={snackbar.severity}
            />
        </div>
    )
}

export default SecondForm
