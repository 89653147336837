import React, { useEffect, useState } from 'react';
import styles from "./ContactForm.module.css";
import dedicated_ip_board from "../../Assets/dedicated_ip_board.svg";
import api from '../../Utils/Network';
import marcusEvans from "../../Assets/marcusevans.svg"

const ContactFound = ({ searchContactDetails, handleCloseModal, handleLinkOwner, formData, hubspotId, handleSecondFormClear, singleUser, handleSelectfromList }) => {
  const [loading, setLoading] = useState(false);
  const [loadingtwo, setLoadingtwo] = useState(false)
  const [isFull, setisFull] = useState(false);
  const [owner, setisOwner] = useState(false)

  const handleClick = async () => {
    setLoading(true);
    try {
      await handleLinkOwner();
    } catch (error) {
      console.error('Error linking owner:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleClicksecond = async (val) => {
    setLoadingtwo(true);
    try {
      await handleLinkOwner(val);
    } catch (error) {
      console.error('Error linking owner:', error);
    } finally {
      setLoadingtwo(false);
    }
  };

  const handleSelect = (contact, id) => {
    handleSelectfromList(contact)
  }


  console.log(hubspotId, "hubspotId")
  const preflightCheck = async () => {
    const userType = localStorage.getItem('userType') || '';
    try {
      const response = await api.get(`/contact_owners_full?hubspotContactId=${hubspotId}&userType=${userType}`);
      setisOwner(response?.data?.ResponseData?.isAlreadyOwner);
      if (response.data?.ResponseData?.isFull) {
        setisFull(true);
      } else {
        setisFull(false);
      }
    } catch (error) {
      console.error('Error making the second request:', error);
    }
  }

  useEffect(() => {
    if (searchContactDetails.length > 0 && hubspotId) {
      preflightCheck();
    }
  }, [hubspotId, searchContactDetails]);

  const handleContactAdmin = () => {
    const email = 'monikam@marcusevansuk.com , anushreed@marcusevansuk.com';
    const subject = encodeURIComponent('Contact Ownership Request');
    const body = encodeURIComponent(
      `Hello,\n\nI would like to request ownership of a contact:\n\nFirst Name: ${singleUser?.FirstName}\nLast Name: ${singleUser?.Surname}\nCompany Name: ${singleUser?.CompanyName
      }\n\nPlease assist me.\n\nThank you.`
    );
    window.location.href = `mailto:${email}?subject=${subject}&body=${body}`;
    handleCloseModal();
    handleSecondFormClear();
  };

  return (
    <>
      <div className={styles.WrapperimgCon}>
        <div className={styles.imgContainer}>
          <img src={marcusEvans} alt="marcus evans" className={styles.meImage} />
        </div>
      </div>


      <p className={styles.contactContainer}>Contact Found</p>
      {/* <div className={styles.dedicatedContainer}>
        <img src={dedicated_ip_board} className={styles.ddimage} alt="dedicated ip board" />
      </div> */}
      <div
        className={`${styles.contactDetails} ${searchContactDetails?.length > 2 ? styles.scrollable : ''}`}
      >
        {searchContactDetails?.map((contact, index) => (
          <div
            key={index}
            className={`${styles.radioContainer} ${(searchContactDetails.length === 1 || hubspotId === contact?.ContactHubSpotId)
              ? styles.borderHighlight
              : ''
              } ${formData?.email === contact.EmailAddress ? styles.emailMatch : styles.noEmailMatch
              }`}
            onClick={searchContactDetails.length > 1 ? () => handleSelect(contact, contact?.ContactHubSpotId) : undefined}
          >
            <div className={styles.contactDetail}>
              <p className={`${styles.color} ${styles.spacing}  `}><span className={styles.fontBold}>First Name</span>: {contact?.FirstName}</p>
              <p className={`${styles.color} ${styles.spacing}  `}><span className={styles.fontBold}>Last Name</span>: {contact?.Surname}</p>
              <p className={`${styles.color} ${styles.spacing}  `}><span className={styles.fontBold}>Company</span>: {contact?.CompanyName}</p>
              {formData?.email === contact?.EmailAddress && contact?.EmailAddress && (
                <p className={`${styles.color} ${styles.spacing}  `}>
                  <span className={styles.fontBold}>Email</span>: {contact?.EmailAddress}
                </p>
              )}
            </div>
          </div>
        ))}
      </div>

      {(isFull && !owner) && <p className={styles.marginTop}>The dedicated numbers of owners are complete for this contact. Please contact admin/support to take ownership of this contact.</p>}
      {owner && (
        <p className={`${styles.marginTop} ${styles.textAlignc}  `}>You are already an owner of this contact.</p>
      )}
      <div className={styles.ButtonForm}>
        {owner ? (
          // If the user is already the owner, disable the button and change its color to gray
          <button
            className={`${styles.pButton} ${styles.disabledButton}`}
            disabled={true}
            style={{ backgroundColor: 'gray', cursor: 'not-allowed', display: 'block', width: '100%' }}
          >
            You are already the owner
          </button>
        ) : (
          isFull ? (
            // If the user is not the owner and isFull is true, show "Contact Admin/Support"
            <button
              className={styles.pButton}
              onClick={handleContactAdmin}
              style={{ display: 'block', width: '100%' }}
            >
              Contact Admin/Support
            </button>
          ) : (
            // If the user is not the owner and isFull is false, show "Proceed with ownership"
            <div style={{ display: "flex", flexDirection: "column", gap: "14px" }}>
              <button
                className={styles.pButton}
                onClick={handleClick}
                disabled={loading}
                style={{ display: 'block', width: '100%' }}
              >
                {loading ? 'Processing...' : 'Own & Create a New Contact'}
              </button>

              <button
                className={styles.pButton}
                onClick={() => handleClicksecond(true)} // Adjust the onClick as per your requirement
                disabled={loadingtwo}
                style={{ display: 'block', width: '100%' }}
              >
                {loadingtwo ? 'Processing...' : 'Own & Redirect to HubSpot'}
              </button>
            </div>
          )
        )}
        <div style={{ marginTop: "10px" }}>
          <button onClick={handleCloseModal} className={styles.skipButton} style={{ display: 'block', width: '100%' }}>
            Skip adding this contact
          </button>
        </div>
      </div>


    </>
  )
}

export default ContactFound

const data = [
  {
    FirstName: 'John',
    Surname: 'Doe',
    CompanyName: 'Example Corp',
    EmailAddress: 'john.doe@example.com',
    ContactHubSpotId: '001',
  },
  {
    FirstName: 'Jane',
    Surname: 'Smith',
    CompanyName: 'Tech Solutions',
    EmailAddress: 'jane.smith@techsolutions.com',
    ContactHubSpotId: '002',
  },
  {
    FirstName: 'Alice',
    Surname: 'Johnson',
    CompanyName: 'Innovative Designs',
    EmailAddress: 'alice.johnson@innodesigns.com',
    ContactHubSpotId: '003',
  },
  {
    FirstName: 'Bob',
    Surname: 'Brown',
    CompanyName: 'Creative Minds',
    EmailAddress: 'bob.brown@creativeminds.com',
    ContactHubSpotId: '004',
  },
];

