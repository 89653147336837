import axios from 'axios';

class Network {
  constructor(baseURL) {
    this.client = axios.create({
      baseURL,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    // Add a request interceptor
    this.client.interceptors.request.use(
      (config) => {
        // Attach the access token to every request if it exists
        const token = localStorage.getItem('access_token');
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    // Add a response interceptor
    this.client.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;
        
        if (error.response) {
          // Handle 401 Unauthorized error
          if (error.response.status === 401 && !originalRequest._retry) {
            console.error('Unauthorized, refreshing token...');
            originalRequest._retry = true;
            try {
              const newTokens = await this.refreshToken();
              // Destructure and save the new tokens
              const { access_token, refresh_token } = newTokens.ResponseData;
              localStorage.setItem('access_token', access_token);
              localStorage.setItem('refresh_token', refresh_token);
              // Retry the original request with the new access token
              return this.client(originalRequest);
            } catch (refreshError) {
              console.error('Error refreshing token:', refreshError);
              localStorage.clear();
              window.location.href = '/';
            }
          }

          // Handle 403 Forbidden error
          if (error.response.status === 403) {
            console.error('Forbidden, clearing local storage...');
            localStorage.clear();
            window.location.href = '/';
          }
        }

        return Promise.reject(error);
      }
    );
  }

  // Method for refreshing the access token
  async refreshToken() {
    const refreshToken = localStorage.getItem('refresh_token');
    if (!refreshToken) {
      throw new Error('No refresh token found');
    }

    try {
      const response = await axios.post('https://hubspotfrontdoor-api.marcusevans.com/auth/refresh', null, {
        params: { refresh_token: refreshToken },
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.data.IsSuccess) {
        return response.data;
      } else {
        throw new Error('Token refresh failed');
      }
    } catch (error) {
      console.error('Error refreshing access token:', error);
      throw error;
    }
  }

  // Method for making GET requests
  get(url, params) {
    return this.client.get(url, { params });
  }

  // Method for making POST requests
  post(url, data) {
    return this.client.post(url, data);
  }

  // Method for making PUT requests
  put(url, data) {
    return this.client.put(url, data);
  }

  // Method for making DELETE requests
  delete(url) {
    return this.client.delete(url);
  }
}

// UAT api
// const api = new Network('https://hubspot-frontdoor-api.crmuat.me');

//for Prod api
const api = new Network('https://hubspotfrontdoor-api.marcusevans.com');

// for dev api 
// const api = new Network('https://hubspot-frontdoor-api-dev.crmuat.me');
export default api;
