import React from 'react';
import styles from "./HubSpotLogin.module.css"
import me from "../../Assets/marcusevans.svg"
import hublogo from "../../Assets/hubspotlogo.svg";
import hubspotIcon from "../../Assets/hubspotIcon.png";
import { Link } from 'react-router-dom';

// UAT env 
// const authUrl = `https://app-eu1.hubspot.com/oauth/authorize?client_id=68c1f155-13eb-4471-8223-6cc8fc32eb07&redirect_uri=https://hubspot-frontdoor.crmuat.me/auth/callback&scope=crm.objects.contacts.write%20oauth`
// dev env
//  const authUrl = `https://app-eu1.hubspot.com/oauth/authorize?client_id=68c1f155-13eb-4471-8223-6cc8fc32eb07&redirect_uri=http://localhost:8001/auth/callback&scope=crm.objects.contacts.write%20oauth`
//Live
const authUrl =  `https://app-eu1.hubspot.com/oauth/authorize?client_id=68c1f155-13eb-4471-8223-6cc8fc32eb07&redirect_uri=https://hubspotfrontdoor.marcusevans.com/auth/callback&scope=crm.objects.contacts.write%20oauth`

const HubSpotLogin = () => {
  return (
    <div>
      <div className={styles.loginContainer}>
        <div className={styles.loginBox}>
          <img src={me} alt="marcus evans" className={styles.marcusLogo} />
          <p className={styles.welcomeMsg}>Welcome to</p>
          <div className={styles.hubspotContainer}>
            <img src={hublogo} alt="HubSpot CRM" className={styles.hubspotLogo} />
            <p className={styles.crmText}>CRM</p>
          </div>

          <Link to={authUrl} className={styles.authButton}>
            <img src={hubspotIcon} alt="Microsoft" className={styles.microsoftLogo} />
            Login with HubSpot
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HubSpotLogin;
